
const digest = 'cfdc5c8c19e83ed39bb8e7af47e2b0f5ca43fe112a2b43f31594e2141d5d5c81';
const css = `._spinner_3vod1_1 {
  width: 2rem;
  height: 2rem;
  color: var(--color-brand);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"spinner":"_spinner_3vod1_1"};
export { css, digest };
  