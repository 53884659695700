
const digest = '701ada61b73bb6450982dac882a98d5af47c54688460bfacd4ca99a9adfb43a5';
const css = `._statusButton_17fjj_1 {
  display: flex;
  align-items: center;
  height: 2rem;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid var(--color--neutral-10);
  background-color: #ffffff;
  padding: 0 0.25rem 0 0.5rem;
}
@media only screen and (min-width: 1600px) {
  ._statusButton_17fjj_1 {
    font-size: 16px;
    height: 2.5rem;
    padding: 0 0.5rem 0 1rem;
  }
}

._statusButton_17fjj_1 svg {
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 0.25rem 0 0;
  fill: var(--color--neutral-10);
}

._statusButton_17fjj_1 span {
  margin-right: 0.75rem;
  color: var(--color-neutral-10);
}

._statusButtonIcon_17fjj_31 circle {
  fill: var(--color--neutral-10);
}

._statusButtonIcon_17fjj_31 path {
  fill: #ffffff;
}

._status_17fjj_1 {
  border: 1px solid var(--color--neutral-20);
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0.75rem;
  cursor: pointer;
}
._icon_17fjj_46 {
  display: inline-block;
  height: 2rem;
  width: 2rem;
  color: var(--color-legacy-gray-3);
}
._content_17fjj_52 {
  display: inline-block;
  margin-left: 0.5rem;
}
._title_17fjj_56 {
  margin-bottom: 0.25rem;
  color: var(--color-neutral-10);
}
._description_17fjj_60 {
  line-height: 12px;
  font-size: 12px;
  color: var(--color--neutral-10);
}

._status_17fjj_1._active_17fjj_66 {
  border: 1px solid var(--color--neutral-10);
}

._active_17fjj_66 ._icon_17fjj_46 {
  color: var(--color--neutral-10);
}
._active_17fjj_66 ._title_17fjj_56 {
  color: var(--color-neutral-40);
}

svg._active_17fjj_66 circle {
  fill: var(--color-legacy-gray-2);
}
svg._active_17fjj_66 path {
  fill: var(--color--neutral-10);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"statusButton":"_statusButton_17fjj_1","statusButtonIcon":"_statusButtonIcon_17fjj_31","status":"_status_17fjj_1","icon":"_icon_17fjj_46","content":"_content_17fjj_52","title":"_title_17fjj_56","description":"_description_17fjj_60","active":"_active_17fjj_66"};
export { css, digest };
  