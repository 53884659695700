
const digest = 'b46fb49c6b64aca9077d1e3a3bd20b4969f199da2292d821a62e3060a50f4905';
const css = `._action_148rp_1 {
  align-items: center;
  background: #fff;
  border: 1px solid var(--color-neutral-10);
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 1.5rem;
  justify-content: center;
  text-align: center;
  width: 1.5rem;
}

._action_148rp_1:focus,
._action_148rp_1:active {
  outline: none;
}

._action_148rp_1:disabled {
  cursor: not-allowed;
}

._ghosted_148rp_25 {
  background: transparent;
  border: 1px solid transparent;
}

._ghosted_148rp_25:hover:enabled {
  background: #fff;
  border: 1px solid var(--color--neutral-10);
}

._wrap_148rp_36 {
  margin-right: 0.5rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"action":"_action_148rp_1","ghosted":"_ghosted_148rp_25 _action_148rp_1","wrap":"_wrap_148rp_36"};
export { css, digest };
  