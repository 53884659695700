
const digest = 'afa860a57b0bfe3b0d746f187d9fb75e9fe0e949946e2c4046af10961e073781';
const css = `._chartLabel_1459u_1 {
  font-size: 0.75rem;
  line-height: 1;
  text-align: center;
  fill: var(--color--neutral-10);
}

._monthLabel_1459u_8 {
  text-transform: uppercase;
}

._middle_1459u_12 {
  text-anchor: middle;
}

._end_1459u_16 {
  text-anchor: end;
}

._chartLabel_1459u_1._highlighted_1459u_20 {
  fill: var(--color-neutral-40);
}

._partial_1459u_24 {
  font-size: 0.625rem;
  font-weight: 600;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"chartLabel":"_chartLabel_1459u_1","monthLabel":"_monthLabel_1459u_8","middle":"_middle_1459u_12","end":"_end_1459u_16","highlighted":"_highlighted_1459u_20","partial":"_partial_1459u_24"};
export { css, digest };
  